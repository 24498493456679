const searchArray = (search, record = [], cols = []) => {
  return record.filter(data => {
    let counter = 0;
    cols.forEach(col => {
      if (
        data[col] &&
        data[col].toUpperCase().indexOf(search.toUpperCase()) !== -1
      )
        counter += 1;
    });
    if (counter > 0) return true;

    return false;
  });
};

export default searchArray;
