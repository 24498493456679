import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Tooltip, Icon } from 'antd';

const CustomersTable = ({ tableData, openModal }) => {
  const columns = [
    {
      key: 'deleteBtn',
      align: 'center',
      width: '2%',
      render: record => (
        <Tooltip title="View record" placement="right">
          <Button
            className="bl-cl"
            icon="profile"
            onClick={() => openModal(record)}
            size="small"
          />
        </Tooltip>
      ),
    },
    {
      align: 'center',
      key: 'isApproved',
      title: 'STATUS',
      render: data => {
        let status = null;
        if (data.isApproved === 'PENDING APPROVAL')
          return <span>{data.isApproved}</span>;
        if (data.isApproved === 'REJECTED')
          status = <span style={{ color: '#c0392b' }}>{data.isApproved}</span>;
        else if (
          data.isApproved === 'RECOMMENDED' ||
          data.isApproved === 'APPROVED'
        )
          status = <span style={{ color: '#27ae60' }}>{data.isApproved}</span>;

        status = (
          <>
            {status}
            {data.comment ? (
              <Tooltip title={data.comment}>
                <Icon
                  style={{ marginLeft: 3 }}
                  type="info-circle"
                  theme="twoTone"
                />
              </Tooltip>
            ) : null}
          </>
        );

        return status;
      },
      filters: [
        {
          text: 'PENDING APPROVAL',
          value: 'PENDING APPROVAL',
        },
        {
          text: 'REJECTED',
          value: 'REJECTED',
        },
        {
          text: 'RECOMMENDED',
          value: 'RECOMMENDED',
        },
        {
          text: 'APPROVED',
          value: 'APPROVED',
        },
      ],
      onFilter: (value, record) => record.isApproved.indexOf(value) === 0,
      sorter: (a, b) => {
        const A = a.isApproved || '';
        const B = b.isApproved || '';
        return A.localeCompare(B);
      },
      defaultSortOrder: 'descend',
      sortDirections: ['descend'],
      width: '2%',
    },
    {
      key: 'bi_name',
      dataIndex: 'bi_name',
      title: 'Company Name',
      align: 'center',
    },
    {
      key: 'bi_address',
      dataIndex: 'bi_address',
      title: 'Address',
      align: 'center',
      width: 300,
    },
    {
      key: 'bi_tel',
      dataIndex: 'bi_tel',
      title: 'Tel. No.',
      align: 'center',
    },
    {
      key: 'bi_email',
      dataIndex: 'bi_email',
      title: 'Email',
      align: 'center',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      bodyStyle={{
        overflowX: 'auto',
      }}
      rowKey="id"
      size="middle"
      bordered
    />
  );
};

CustomersTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
};
export default CustomersTable;
