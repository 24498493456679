import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input } from 'antd';
import Confirm from '../Confirm';

const PrInformationModal = props => {
  const { visible, data, closeModal, updateRemarks, setLoading } = props;
  const [remarks, setRemarks] = useState(data.remarks);
  return (
    <Modal
      visible={visible}
      title="Add remarks"
      onCancel={() => closeModal('remarks')}
      width="40%"
      onOk={() =>
        Confirm(updateRemarks, null, { id: data.id, remarks }, setLoading)
      }
      style={{ top: 20 }}
    >
      <Input.TextArea
        rows={2}
        placeholder="Input remarks"
        value={remarks}
        onChange={e => setRemarks(e.target.value)}
      />
    </Modal>
  );
};
PrInformationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  closeModal: PropTypes.func.isRequired,
  updateRemarks: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default PrInformationModal;
