import React from 'react';
import PropTypes from 'prop-types';

const PrItemTable = ({ prItems }) => {
  return (
    <table className="custom-table" border={1}>
      <thead>
        <tr>
          <th>Code</th>
          <th>Material Specification</th>
          <th>Unit</th>
          <th>Currency</th>
          <th>Quantity</th>
          <th>Unit price</th>
          <th>Amount</th>
          <th>Date needed</th>
          <th>Budget Price</th>
        </tr>
      </thead>
      <tbody>
        {prItems.map(item => (
          <tr key={item.id}>
            <td>{item.code}</td>
            <td>{item.mspecs}</td>
            <td>{item.unit}</td>
            <td>{item.currency}</td>
            <td>{item.quantity}</td>
            <td>{item.unitPrice}</td>
            <td align="right">{item.amount.toFixed(2)}</td>
            <td>{item.dateNeeded}</td>
            <td align="right">{item.budgetPrice}</td>
          </tr>
        ))}
        {prItems.length > 0 && (
          <tr>
            <td colSpan="6" />
            <td align="right">
              {prItems.reduce((acc, val) => acc + val.amount, 0).toFixed(2)}
            </td>
            <td colSpan="2" />
          </tr>
        )}
        {prItems.length === 0 && (
          <tr>
            <td colSpan="9">NO RECORD</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
PrItemTable.propTypes = {
  prItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PrItemTable;
