import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Descriptions } from 'antd';

import Confirm from '../Confirm';

const CustomerDetails = props => {
  const { data, closeModal, visible, updateCustomer, setLoading } = props;
  return (
    <Modal
      visible={visible}
      footer={null}
      style={{
        top: 25,
      }}
      width={650}
      onCancel={closeModal}
    >
      <Descriptions
        column={2}
        size="small"
        title="BUSINESS INFORMATION"
        bordered
      >
        <Descriptions.Item span={2} label="Business name">
          {data.bi_name}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Business address">
          {data.bi_address}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Nature of business">
          {data.bi_nature}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Tel No.">
          {data.bi_tel}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Fax No.">
          {data.bi_fax}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="SSS No.">
          {data.bi_sss}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Tin No.">
          {data.bi_tin}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Email address">
          {data.bi_email}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Nature of business">
          {data.bi_nature}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Years in business">
          {data.bi_yearsBusiness}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Business Premises">
          {data.bi_premises}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Business Type">
          {data.bi_type}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Contact Person">
          {data.bi_contact}
        </Descriptions.Item>
        <Descriptions.Item span={1} label="Position">
          {data.bi_contactposition}
        </Descriptions.Item>
      </Descriptions>
      {data.isApproved === 'PENDING APPROVAL' && (
        <div className="ant-modal-footer" style={{ margin: '0 -20px 0 -20px' }}>
          <Button
            onClick={() => {
              Confirm(
                updateCustomer,
                null,
                { id: data.id, method: 'rejected', src: 'om' },
                setLoading,
                closeModal
              );
            }}
            className="red-cl"
            icon="close-circle"
          >
            Reject
          </Button>
          <Button
            icon="check-circle"
            type="primary"
            onClick={() => {
              Confirm(
                updateCustomer,
                null,
                { id: data.id, method: 'approved', src: 'om' },
                setLoading,
                closeModal
              );
            }}
          >
            Approve
          </Button>
        </div>
      )}
    </Modal>
  );
};
CustomerDetails.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  visible: PropTypes.bool.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};
export default CustomerDetails;
