import React from 'react';
import moment from 'moment';
import { Table, Tooltip, Button } from 'antd';

const PurchaseOrderTable = ({
  poList,
  customers,
  pageLength,
  paginate,
  onSizeChange,
  onPageChange,
  openModal,
}) => {
  const tableColumns = [
    {
      key: 'viewPo',
      align: 'center',
      width: '2%',
      render: data => {
        return (
          <Tooltip placement="right" title="View purchase order details">
            <Button
              onClick={() => openModal(data)}
              className="bl-cl"
              icon="profile"
              size="small"
            />
          </Tooltip>
        );
      },
    },
    {
      key: 'status',
      title: 'Status',
      align: 'center',
      render: d => {
        let color = d.status === 'OPEN' ? '#27ae60' : '#218c74';
        return <div style={{ color, fontWeight: 600 }}>{d.status}</div>;
      },
      sorter: (a, b) => {
        const A = a.status ? a.status : '';
        const B = b.status ? b.status : '';
        return A.localeCompare(B);
      },
      filters: [
        {
          text: 'OPEN',
          value: 'OPEN',
        },
        {
          text: 'SERVED',
          value: 'SERVED',
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      key: 'po_num',
      title: 'PO No.',
      dataIndex: 'po_num',
      align: 'center',
    },
    {
      key: 'date',
      title: 'Date Issued',
      dataIndex: 'date',
      align: 'center',
      sorter: (a, b) => {
        const A = a.date ? a.date : '';
        const B = b.date ? b.date : '';
        return moment(A).unix() - moment(B).unix();
      },
    },
    {
      key: 'customer',
      title: 'Customer',
      dataIndex: 'customerLabel',
      align: 'center',
      filters: customers.map(customer => ({
        text: customer.bi_name,
        value: customer.bi_name,
      })),
      onFilter: (value, record) => record.customerLabel.indexOf(value) === 0,
      sorter: (a, b) => {
        const A = a.customerLabel ? a.customerLabel : '';
        const B = b.customerLabel ? b.customerLabel : '';
        return A.localeCompare(B);
      },
    },
    {
      key: 'delivered',
      title: 'No. of Items/Delivered/Qty',
      align: 'center',
      render: data => (
        <div>
          {data.totalItems} Item/s - {data.totalDelivered}/{data.totalQuantity}
        </div>
      ),
      sorter: (a, b) => {
        return a.totalQuantity - b.totalQuantity;
      },
    },
    {
      key: 'currency',
      title: 'Currency',
      dataIndex: 'currency',
      align: 'center',
      sorter: (a, b) => {
        const A = a.currency ? a.currency : '';
        const B = b.currency ? b.currency : '';
        return A.localeCompare(B);
      },
      filters: [
        {
          text: 'PHP',
          value: 'PHP',
        },
        {
          text: 'USD',
          value: 'USD',
        },
      ],
      onFilter: (value, record) => record.currency.indexOf(value) === 0,
    },
  ];
  return (
    <>
      <Table
        rowKey="id"
        bordered
        size="small"
        columns={tableColumns}
        dataSource={poList}
        style={{ overflowX: 'auto', margin: '10px 0 10px 0' }}
        pagination={{
          total: pageLength,
          current: paginate?.page,
          pageSize: paginate?.pageSize,
          defaultPageSize: paginate?.pageSize,
          showSizeChanger: true,
          onChange: onPageChange,
          onShowSizeChange: onSizeChange,
          pageSizeOptions: ['10', '15', '25'],
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          position: 'both',
        }}
      />
    </>
  );
};

export default PurchaseOrderTable;
