import React, { useContext, useEffect, useState } from 'react';

import { PContext } from '../context/PendingContext';
import { Utils } from '../context/UtilsContext';
import Layout from '../layout/Layout';
import PurchaseOrderTable from '../components/PurchaseOrder/PurchaseOrderTable';
import PoFilter from '../components/Filter/PoFilter';
import PurchaseOrderModal from '../components/PurchaseOrder/PurchaseOrderModal';

const PurchaseOrderView = () => {
  const {
    getPurchaseOrder,
    getCustomers,
    values: { poList, poListLength, customers },
  } = useContext(PContext);
  const { setLoading } = useContext(Utils);
  // filter
  const defaultFilter = {
    search: '',
    sort: 'latest',
    customer: null,
    status: null,
    month: null,
    year: null,
  };
  const [filter, setFilter] = useState(defaultFilter);
  const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 });
  const [modalData, setModalData] = useState({ visible: false, data: {} });

  const openModal = data => setModalData({ visible: true, data });
  const closeModal = () => setModalData({ visible: false, data: {} });

  const onFilter = () => getPurchaseOrder(setLoading, filter);
  const onResetFilter = () => {
    setFilter(defaultFilter);
    getPurchaseOrder(setLoading, defaultFilter);
  };
  //end filter

  const shouldFetchFromServer = ({ page, pageSize }) => {
    const totalData = page * pageSize;
    const currentDataLength = poList.length; //
    const dataTotalLength = poListLength;
    let shouldReload = false;
    if (
      totalData > currentDataLength &&
      (dataTotalLength > totalData ||
        (dataTotalLength > totalData - pageSize &&
          dataTotalLength > currentDataLength))
    ) {
      shouldReload = true;
    }

    const shouldLoadAll =
      (totalData > dataTotalLength && dataTotalLength > totalData - pageSize) ||
      totalData === dataTotalLength
        ? `&start=${currentDataLength}&end=${dataTotalLength}`
        : ``;

    return {
      shouldReload,
      newPage: Math.floor(currentDataLength / 1000) + 1,
      shouldLoadAll,
    };
  };

  const onPageChange = (page, pageSize) => {
    const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
      page,
      pageSize,
    });
    if (shouldReload) {
      getPurchaseOrder(setLoading, filter, newPage, shouldLoadAll);
    }
    setPaginate({ page, pageSize });
  };

  const onSizeChange = (page, pageSize) => {
    const { shouldReload, newPage, shouldLoadAll } = shouldFetchFromServer({
      page,
      pageSize,
    });
    if (shouldReload) {
      getPurchaseOrder(setLoading, filter, newPage, shouldLoadAll);
    }
    setPaginate({ page, pageSize });
  };

  const refreshContent = () => {
    getPurchaseOrder(setLoading, defaultFilter);
    setPaginate({ page: 1, pageSize: 10 });
  };

  useEffect(() => {
    if (customers.length < 1) getCustomers();
    getPurchaseOrder(setLoading, defaultFilter);
  }, []);

  return (
    <>
      <br />
      <h4 style={{ fontWeight: 600 }}>PURCHASE ORDER</h4>
      <PoFilter
        filter={filter}
        setFilter={setFilter}
        onFilter={onFilter}
        onResetFilter={onResetFilter}
        customers={customers}
      />
      <PurchaseOrderTable
        poList={poList}
        setLoading={setLoading}
        paginate={paginate}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        pageLength={poListLength}
        customers={customers}
        openModal={openModal}
      />

      <PurchaseOrderModal
        closeModal={closeModal}
        data={modalData.data}
        visible={modalData.visible}
      />

      {/* visible, id, po */}
    </>
  );
};

export default Layout(PurchaseOrderView);
