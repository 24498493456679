import React, { Fragment } from 'react';
import { Collapse, Row, Col, Button, Select, Icon, Input } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const PolistFilter = ({
  customers,
  filter,
  setFilter,
  onFilter,
  onResetFilter,
}) => {
  const customerOpt = customers
    .filter(customer => customer.isApproved === 'APPROVED')
    .map(data => (
      <Option key={data.id} value={data.id}>
        {data.bi_name}
      </Option>
    ));
  const yearOpt = [
    2015,
    2016,
    2017,
    2018,
    2019,
    2020,
    2021,
    2022,
    2023,
    2024,
    2025,
    2026,
    2027,
    2028,
    2029,
    2030,
  ];
  return (
    <>
      <Collapse style={{ margin: '10px 0 10px 0' }} defaultActiveKey={[1]}>
        <Panel header="ADVANCE FILTER" key="1">
          <Row gutter={16}>
            <Col lg={{ span: 6 }}>
              Search : <br />
              <Input
                value={filter.search}
                onChange={e => setFilter({ ...filter, search: e.target.value })}
                placeholder="Input purchase order no."
              />
            </Col>
            <Col lg={{ span: 6 }}>
              Customer : <br />
              <Select
                onChange={val => setFilter({ ...filter, customer: val })}
                value={filter.customer}
                style={{ width: '100%' }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                loading={customers.length < 1}
                showSearch
              >
                <Option value={null}>All</Option>
                {customerOpt}
              </Select>
            </Col>
            <Col lg={{ span: 6 }}>
              Sort : <br />
              <Select
                value={filter.sort}
                onChange={sort => setFilter({ ...filter, sort })}
                style={{ width: '100%' }}
              >
                <Option value="latest">
                  <Icon type="sort-descending" />
                  Latest record
                </Option>
                <Option value="oldest">
                  <Icon type="sort-ascending" />
                  Oldest record
                </Option>
                <Option value="date-asc">
                  <Icon type="sort-ascending" />
                  Date ascending
                </Option>
                <Option value="date-desc">
                  <Icon type="sort-ascending" />
                  Date descending
                </Option>
              </Select>
            </Col>
            <Col lg={{ span: 6 }}>
              Status : <br />
              <Select
                value={filter.status}
                onChange={status => setFilter({ ...filter, status })}
                style={{ width: '100%' }}
              >
                <Option value={null}>All</Option>
                <Option value="OPEN">OPEN</Option>
                <Option value="SERVED">SERVED</Option>
              </Select>
            </Col>
          </Row>

          <Row style={{ marginTop: 8 }} gutter={16}>
            <Col lg={{ span: 6 }}>
              Month : <br />
              <Select
                onChange={val => setFilter({ ...filter, month: val })}
                value={filter.month}
                style={{ width: '100%' }}
              >
                <Option value={null}>All</Option>
                <Option value={1}>Jan</Option>
                <Option value={2}>Feb</Option>
                <Option value={3}>Mar</Option>
                <Option value={4}>Apr</Option>
                <Option value={5}>May</Option>
                <Option value={6}>Jun</Option>
                <Option value={7}>Jul</Option>
                <Option value={8}>Aug</Option>
                <Option value={9}>Sep</Option>
                <Option value={10}>Oct</Option>
                <Option value={11}>Nov</Option>
                <Option value={12}>Dec</Option>
              </Select>
            </Col>
            <Col lg={{ span: 6 }}>
              Year : <br />
              <Select
                value={filter.year}
                style={{ width: '100%' }}
                onChange={val => setFilter({ ...filter, year: val })}
              >
                <Option value={null}>All</Option>
                {yearOpt.map(val => (
                  <Option key={val} value={val}>
                    {val}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col lg={{ span: 6 }}>
              <br />
              <Button onClick={onFilter} className="bl-cl" icon="filter" block>
                Filter
              </Button>
            </Col>
            <Col lg={{ span: 6 }}>
              <br />
              <Button
                onClick={onResetFilter}
                className="bl-cl"
                icon="undo"
                block
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </>
  );
};

export default PolistFilter;
