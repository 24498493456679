import React from 'react';
import PropTypes from 'prop-types';
import { Collapse, Row, Col, Button, Icon, Input, Select } from 'antd';

const { Panel } = Collapse;
const { Option } = Select;

const Filter = ({ filter, setFilter, onResetFilter, onFilter }) => {
  return (
    <Collapse style={{ margin: '10px 0 10px 0' }} defaultActiveKey={[1]}>
      <Panel header="ADVANCE FILTER" key="1">
        <Row gutter={16}>
          <Col lg={{ span: 6 }}>
            Search:
            <Input
              onChange={e => setFilter({ ...filter, search: e.target.value })}
              onPressEnter={onFilter}
              value={filter.search}
              placeholder="Search PO, JO, PR"
            />
          </Col>
          <Col lg={{ span: 6 }}>
            Status:
            <Select
              onChange={status => setFilter(data => ({ ...data, status }))}
              value={filter.status}
              style={{ width: '100%' }}
            >
              <Option value={null}>All</Option>
              <Option value="APPROVED">APPROVED</Option>
              <Option value="PENDING">PENDING</Option>
              <Option value="REJECTED">REJECTED</Option>
            </Select>
          </Col>
          <Col lg={{ span: 6 }}>
            Sort:
            <Select
              onChange={sort => setFilter(data => ({ ...data, sort }))}
              value={filter.sort}
              style={{ width: '100%' }}
            >
              <Option value={null}>
                <Icon type="sort-descending" />
                Latest record
              </Option>
              <Option value="asc">
                <Icon type="sort-ascending" />
                Oldest record
              </Option>
            </Select>
          </Col>
          <Col lg={{ span: 3 }}>
            <br />
            <Button icon="filter" onClick={onFilter} className="bl-cl" block>
              Filter
            </Button>
          </Col>
          <Col lg={{ span: 3 }}>
            <br />
            <Button className="bl-cl" onClick={onResetFilter} icon="undo" block>
              Reset
            </Button>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};
Filter.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  setFilter: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};
export default Filter;
