import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Tooltip, Table, Icon, Badge } from 'antd';
import Confirm from '../Confirm';
import { decodedToken } from '../../config/token';

const PendingListTable = props => {
  const {
    prList,
    openModal,
    approveOrReject,
    setLoading,
    paginate,
    onPageChange,
    onSizeChange,
    recordLength,
  } = props;
  const { position } = decodedToken();

  const columns = [
    {
      key: 'checkInfoBtn',
      align: 'center',
      width: '2%',
      render: data => (
        <Tooltip placement="right" title="View purchase request details">
          <Button
            onClick={() => openModal(data, 'pr')}
            icon="profile"
            size="small"
            className="bl-cl"
          />
        </Tooltip>
      ),
    },
    {
      key: 'approveBtn',
      align: 'center',
      width: '2%',
      render: data => {
        if (position === 'Manager' && data.status !== 'PENDING')
          return <Icon type="stop" className="bl-cl" />;

        if (position === 'Deputy Manager' && data.status !== 'RECOMMENDED')
          return <Icon type="stop" className="bl-cl" />;

        return (
          <Tooltip placement="right" title="Approve request">
            <Button
              onClick={() =>
                Confirm(approveOrReject, null, 'approved', data.id, setLoading)
              }
              icon="check-circle"
              size="small"
              className="bl-cl"
            />
          </Tooltip>
        );
      },
    },
    {
      key: 'rejectBtn',
      align: 'center',
      width: '2%',
      render: data => {
        if (position === 'Manager' && data.status !== 'PENDING')
          return <Icon type="stop" className="bl-cl" />;

        if (position === 'Deputy Manager' && data.status !== 'RECOMMENDED')
          return <Icon type="stop" className="bl-cl" />;

        return (
          <Tooltip placement="right" title="Reject request">
            <Button
              onClick={() =>
                Confirm(approveOrReject, null, 'rejected', data.id, setLoading)
              }
              icon="close-circle"
              size="small"
              className="red-cl"
            />
          </Tooltip>
        );
      },
    },
    {
      key: 'addComment',
      align: 'center',
      width: '2%',
      render: data => (
        <Tooltip placement="right" title="Add remarks">
          <Badge dot={data.remarks !== ''}>
            <Button
              onClick={() =>
                openModal({ id: data.id, remarks: data.remarks }, 'remarks')
              }
              icon="message"
              size="small"
              className="bl-cl"
            />
          </Badge>
        </Tooltip>
      ),
    },
    {
      key: 'status',
      title: 'Status',
      align: 'center',
      render: data => {
        let color = '#27ae60';
        if (data.status === 'APPROVED') color = '#218c74';
        else if (data.status === 'REJECTED') color = '#c0392b';
        return <div style={{ color, fontWeight: 750 }}>{data.status}</div>;
      },
    },
    {
      key: 'supplier',
      title: 'Supplier',
      dataIndex: 'supplier',
      align: 'center',
    },
    {
      key: 'poNum',
      title: 'Purchase Order',
      dataIndex: 'poNum',
      align: 'center',
    },
    {
      key: 'joNum',
      title: 'Job Order',
      dataIndex: 'joNum',
      align: 'center',
    },
    {
      key: 'prNum',
      title: 'Purchase Requisition',
      dataIndex: 'prNum',
      align: 'center',
    },
    {
      key: 'date',
      title: 'Date requested',
      align: 'center',
      render: data => `${moment(data.created_at).format('YYYY-MM-DD')}
        (${moment(data.created_at).fromNow()})`,
    },
  ];
  return (
    <Table
      bodyStyle={{ fontSize: 12, overflowX: 'auto' }}
      size="small"
      columns={columns}
      dataSource={prList}
      rowKey="id"
      pagination={{
        total: recordLength,
        current: paginate.page,
        pageSize: paginate.pageSize,
        defaultPageSize: paginate.pageSize,
        showSizeChanger: true,
        onChange: onPageChange,
        onShowSizeChange: onSizeChange,
        pageSizeOptions: ['10', '15', '25'],
        showTotal: (total, range) =>
          `Showing ${range[0]}-${range[1]} of ${total} items`,
        position: 'both',
      }}
      bordered
    />
  );
};

PendingListTable.propTypes = {
  prList: PropTypes.arrayOf(PropTypes.object).isRequired,
  openModal: PropTypes.func.isRequired,
  approveOrReject: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  recordLength: PropTypes.number.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  paginate: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PendingListTable;
