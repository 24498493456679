import React, { useEffect, useState, useContext } from 'react';

import { Modal, Tree, Result, Spin } from 'antd';
import { PContext } from '../../context/PendingContext';

const OverallDetailsModal = props => {
  const {
    visible,
    data: { id, po_num },
    closeModal,
  } = props;

  const [tree, setTrees] = useState({ treeData: [], treeDataParentKeys: [] });
  const { getPoDetails } = useContext(PContext);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (visible) {
      getPoDetails(id)
        .then(res => {
          const { treeData, treeDataParentKeys } = res;
          setTrees({ treeData, treeDataParentKeys });
        })
        .finally(() => setLoading(false));
    }
  }, [visible]);
  return (
    <Modal
      onCancel={() => (isLoading ? null : closeModal())}
      style={{ top: 20 }}
      visible={visible}
      width="75%"
      title={po_num}
      footer={null}
    >
      <Spin spinning={isLoading}>
        {tree.treeData.length > 0 ? (
          <Tree blockNode defaultExpandAll treeData={tree.treeData} showLine />
        ) : (
          <Result title="NO ITEMS" status="info" />
        )}
      </Spin>
    </Modal>
  );
};

export default OverallDetailsModal;
