import React, { useContext, useEffect, useState } from 'react';
import { Button, Input } from 'antd';

import Layout from '../layout/Layout';
import { Utils } from '../context/UtilsContext';
import { PContext } from '../context/PendingContext';
import CustomersTable from '../components/Customers/CustomersTable';
import searchArray from '../components/searchArray';
import CustomerDetails from '../components/Customers/CustomerDetails';

const CustomerView = () => {
  const { values, getCustomers, updateCustomer } = useContext(PContext);
  const { setLoading } = useContext(Utils);

  const [modalData, setModalData] = useState({ visible: false, data: {} });
  const [search, setSearch] = useState('');

  const openModal = data => setModalData({ data, visible: true });
  const closeModal = () => setModalData({ visible: false, data: {} });

  useEffect(() => {
    getCustomers(setLoading);
  }, []);
  const { customers } = values;
  const filteredCustomer = searchArray(search, customers, ['bi_name']);
  return (
    <>
      <br />
      <h4 style={{ fontWeight: 600 }}>CUSTOMERS LIST</h4>
      <Input.Search
        placeholder="Search"
        onChange={e => setSearch(e.target.value)}
        value={search}
        style={{ width: '100%', maxWidth: 400, margin: '5px 0' }}
      />
      <CustomersTable
        setLoading={setLoading}
        openModal={openModal}
        tableData={filteredCustomer}
      />

      <CustomerDetails
        visible={modalData.visible}
        data={modalData.data}
        closeModal={closeModal}
        updateCustomer={updateCustomer}
        setLoading={setLoading}
      />
    </>
  );
};

export default Layout(CustomerView);
