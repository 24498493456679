import decode from 'jwt-decode';
import axios from 'axios';
import { API } from './config';

export const getToken = () => localStorage.getItem('token');

export const setToken = token => localStorage.setItem('token', token);

export const headers = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const decodedToken = () => {
  const token = getToken();

  if (!token) return {};

  const decoded = decode(token);

  return decoded;
};

export const isLoggedIn = () => {
  const token = getToken();
  if (token) {
    if (!isExpired(token)) return true;

    return false;
  }
  return false;
};

export const isExpired = token => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) return true;
    return false;
  } catch (err) {
    return false;
  }
};

export const logout = () => {
  const token = getToken();

  axios
    .post(`${API}logout/cposms?token=${token}`)
    .then(res => {
      console.log(res.data.message);
      localStorage.removeItem('token');
      window.location.href = '/';
    })
    .catch(err => {
      console.log(err.message);
      localStorage.removeItem('token');
      window.location.href = '/';
    });
};
