import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';

const PrSummary = ({ prTotal, withExistingRm, itemTotalBudget }) => {
  const itemTotalWithMargin =
    itemTotalBudget > 0 ? (itemTotalBudget * 1.2).toFixed(2) : 0;

  const difference = itemTotalWithMargin
    ? `${Math.abs(itemTotalWithMargin - prTotal).toFixed(2)} (${(
        ((itemTotalWithMargin - prTotal) / itemTotalWithMargin) *
        100
      ).toFixed(2)}%)`
    : `No total budget price`;

  return (
    <>
      <h4 style={{ fontWeight: 600 }}>SUMMARY</h4>
      <Descriptions
        style={{
          margin: '10px 0 10px 0',
          fontSize: '12px',
        }}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="With existing raw materials purchases?">
          {withExistingRm ? <b className="red-cl">YES</b> : 'NONE'}
        </Descriptions.Item>
        <Descriptions.Item label="Purchase request total">
          {prTotal}
        </Descriptions.Item>
        <Descriptions.Item label="Item total budget price + 20% margin">
          {itemTotalWithMargin}
        </Descriptions.Item>
        <Descriptions.Item label="Difference between total item budget and P.R total">
          {difference}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

PrSummary.propTypes = {
  prTotal: PropTypes.number.isRequired,
  withExistingRm: PropTypes.bool.isRequired,
  itemTotalBudget: PropTypes.number.isRequired,
}

export default PrSummary;
