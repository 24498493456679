export const GET_PENDINGPR = 'GET_PENDINGPR';
export const UPDATE_RECORD = 'UPDATE_RECORD';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const GET_PURCHASEORDERS = 'GET_PURCHASEORDERS';

export const reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case GET_PENDINGPR:
      return {
        ...state,
        prList:
          payload.page === 1
            ? payload.prList
            : state.prList.concat(payload.prList),
        prListLength: payload.prListLength,
      };
    case UPDATE_RECORD:
      return {
        ...state,
        prList: [
          ...state.prList.slice(
            0,
            state.prList.findIndex(
              data => data.id === payload.updatedRequest.id
            )
          ),
          { ...payload.updatedRequest },
          ...state.prList.slice(
            state.prList.findIndex(
              data => data.id === payload.updatedRequest.id
            ) + 1
          ),
        ],
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: payload.customers,
      };
    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: [
          ...state.customers.slice(
            0,
            state.customers.findIndex(
              data => data.id === payload.newCustomer.id
            )
          ),
          payload.newCustomer,
          ...state.customers.slice(
            state.customers.findIndex(
              data => data.id === payload.newCustomer.id
            ) + 1
          ),
        ],
      };
    case GET_PURCHASEORDERS:
      return {
        ...state,
        poList:
          action.payload.page < 2
            ? action.payload.po
            : state.po.concat(action.payload.po),
        poListLength: payload.poLength,
      };
    default:
      return state;
  }
};
