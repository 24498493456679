import React from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';

const PoDetails = props => {
  const { poDetails, poItems, poHistory } = props;
  return (
    <>
      <Descriptions
        style={{
          margin: '10px 0 10px 0',
          fontSize: '12px',
        }}
        size="small"
        bordered
      >
        <Descriptions.Item label="Customer name">
          {poDetails.customerName}
        </Descriptions.Item>
        <Descriptions.Item label="Purchase order">
          {poDetails.poNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Date">{poDetails.poDate}</Descriptions.Item>
      </Descriptions>

      <table className="custom-table" border={1}>
        <thead>
          <tr>
            <th>Code</th>
            <th>Item Description</th>
            <th>Quantity</th>
            <th>Currency</th>
            <th>Unit price</th>
            <th>Budget Price</th>
            <th>Amount</th>
            <th>Budget Price Amount</th>
          </tr>
        </thead>
        <tbody>
          {poItems.map(item => (
            <tr
              style={{
                backgroundColor: item.isMatchItem ? '#74b9ff' : '#fffff',
              }}
              key={item.id}
            >
              <td>{item.code}</td>
              <td>{item.itemDescription}</td>
              <td>{item.quantity}</td>
              <td>{item.currency}</td>
              <td align="right">{item.unitPrice}</td>
              <td align="right">{item.budgetPrice}</td>
              <td align="right">{item.totalAmount.toFixed(2)}</td>
              <td align="right">{item.budgetPriceTotal.toFixed(2)}</td>
            </tr>
          ))}
          {poItems.length > 0 && (
            <tr>
              <td colSpan="6" />
              <td align="right">
                {poItems
                  .reduce((acc, val) => acc + val.totalAmount, 0)
                  .toFixed(2)}
              </td>
              <td align="right">
                {poItems
                  .reduce((acc, val) => acc + val.budgetPriceTotal, 0)
                  .toFixed(2)}
              </td>
            </tr>
          )}
          {poItems.length === 0 && (
            <tr>
              <td colSpan="8">NO RECORD</td>
            </tr>
          )}
        </tbody>
      </table>
      <br />
      <h4 style={{ fontWeight: 600 }}>RAW MATERIALS PURCHASED HISTORY</h4>
      <table className="custom-table" border={1}>
        <thead>
          <tr>
            <th>Supplier</th>
            <th>Job Order/s</th>
            <th>Purchase Request/s</th>
            <th>Purchase Order</th>
            <th>Item Description/s</th>
            <th>Total Amount</th>
            <th>Sent to Supplier</th>
          </tr>
        </thead>
        <tbody>
          {poHistory.map(history => (
            <tr key={history.poNumber}>
              <td>{history.supplierName}</td>
              <td>{history.joNumber}</td>
              <td>{history.prNumbers}</td>
              <td>{history.poNumber}</td>
              <td>{history.itemDescription}</td>
              <td align="right">{history.totalAmount.toFixed(2)}</td>
              <td>{history.isSent}</td>
            </tr>
          ))}
          {poHistory.length > 0 && (
            <tr>
              <td colSpan="5" />
              <td align="right">
                {poHistory
                  .reduce((acc, val) => acc + val.totalAmount, 0)
                  .toFixed(2)}
              </td>
              <td />
            </tr>
          )}
          {poHistory.length === 0 && (
            <tr>
              <td colSpan="7">NO RECORD</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};
PoDetails.propTypes = {
  poDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  poItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  poHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PoDetails;
